import React, {useState} from 'react';
import './Header.css';
import uppercaseLogo from '../../assets/uppercase-logo.svg'
import arrow from '../../assets/arrow.svg'

interface Props {
  setEnglish: boolean;
}

function Header({setEnglish}: Props) {
  const [active, setActive] = useState('no');
  return (
      <div className="Header">
        <img src={uppercaseLogo} className="Logo" alt="uppercase logo"/>
        <div className="Header-bottom">
          <a href={'http://uppercase.no/'} className="Link">
            <img src={arrow} alt="uppercase logo"/>
            uppercase.no
          </a>
          <div className="Language">
            <button className={active === 'no' ? 'Active' : null} onClick={() => {
              setEnglish(false);
              setActive('no');
            }}>No
            </button>
            <span> / </span>
            <button className={active === 'eng' ? 'Active' : null} onClick={() => {
              setEnglish(true);
              setActive('eng');
            }}>Eng
            </button>
          </div>
        </div>
      </div>
  );
}

export default Header;
