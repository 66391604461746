import React, { useState } from 'react';
import './App.css';
import Header from "./components /Header/Header";
import Info from "./components /Info/Info";
import Progress from "./components /Progress/Progress";

function App() {
  const [english, setEnglish] = useState(false);
  return (
    <div className="App">
      <Header setEnglish={setEnglish}/>
      <Info english={english}/>
      <Progress english={english}/>
    </div>
  );
}

export default App;
