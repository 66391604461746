import React from "react";
import './Info.css';
import eas from "../../assets/partners/eas.svg";
import norwaygrants from "../../assets/partners/norway-grants.svg";

interface Props {
  english: boolean;
}

const englishContent = [
  {
    title: 'Info',
    content: (
        <>
          <p>
            Waybiller OÜ and Uppercase have established a co-operation introducing Waybiller platform to the norwegian market through the Estonian-Norwegian co-operation program “Green ICT”
          </p>
          <p>
            Project number: EU53940
          </p>
        </>
    ),
  },
  {
    title: 'Budget',
    content: (
        <>
          <p>
            The total budget over a 3 year project period is set at 470434.30 EUR
          </p>
        </>
    ),
  },
  {
    title: 'Partners',
    content: (
        <>
          <a href={'https://waybiller.com/'}>Waybiller OU</a> and <a href={'http://uppercase.no/'}>Uppercase AS</a>
          <div className="Partners-logo">
            <img src={norwaygrants} alt="norway grants logo"/>
            <img src={eas} alt="eas logo"/>
          </div>
        </>
    ),
  },
];

const norwegianContent = [
  {
    title: 'Info',
    content: (
        <>
          <p>
            Waybiller OÜ og Uppercase har etablert et samarbeid som introduserer Waybiller-plattformen til det norske markedet gjennom det estisk-norske samarbeidsprogrammet "Green ICT".          </p>
          <p>
            Prosjektnummer: EU53940
          </p>
        </>
    ),
  },
  {
    title: 'Budsjett',
    content: (
        <>
          <p>
            Det totale budsjettet over en 3-årig prosjektperiode er satt til 470434,30 EUR
          </p>
        </>
    ),
  },
  {
    title: 'Partnere',
    content: (
        <>
          <a href={'https://waybiller.com/'}>Waybiller OÜ</a> og <a href={'http://uppercase.no/'}>Uppercase AS</a>
          <div className="Partners-logo">
            <img src={norwaygrants} alt="norway grants logo"/>
            <img src={eas} alt="eas logo"/>
          </div>
        </>
    ),
  },
];


function Info({english}: Props) {
  const content = english ? englishContent : norwegianContent;

  return (
      <div className="Info">
        {content.map((item, key) => (
            <div key={key}>
              <h4>{item.title}</h4>
              {item.content}
            </div>
        ))}
      </div>
  );
}

export default Info;
