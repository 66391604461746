import './Progress.css';
import cars from "../../assets/cars.png";
import React from "react";

interface Props {
  english: boolean;
}

const englishContent = [
  {
    subtitle: 'Waybiller OU has been granted the co-operation grant and we will kick off the collab in august!',
    date: '1.08.2020',
    text: (
        <>
          <p>Our first mission will be to have a closer look at the norwegian market situation and start the translation
            of the Waybiller software to norwegian.</p>
          <p> More to come!</p>
        </>
    ),
  },
  {
    image: (
        <img src={cars} alt="cars"/>
    ),
    subtitle: 'The initial work is well under way, norwegian language options will soon be ready.',
    date: '10.09.2020',
    text: (
        <>
        <p>The initial work is well under way and the platform and landingpages is getting ready for the norwegian market introduction. We are also gathering a list of potential candidates and pilot customers in the target group</p>
        <p>
          Want to give waybiller a try? <a href="mailto: katarina@uppercase.no">Contact us here</a>
        </p>
          </>
    ),
  },
  {
    subtitle: 'Norwegian is available + potential first pilot customer reached!',
    date: '20.10.2020',
    text: (
        <p>
          Norwegian is available. We finally launched the service with norwegian language options and only minor language tweaks are left on the platform itself to optimize it for norwegian lingo. Also, a potential first pilot customer is reached and the dialogue is promising.
        </p>
    ),
  },
];

const norwegianContent = [
  {
    subtitle: 'Waybiller OU har fått samarbeidsstipend, og vi starter samarbeidet i august!',
    date: '1.08.2020',
    text: (
        <>
          <p>Vår første oppgave vil være å se nærmere på den norske markedssituasjonen og starte oversettelsen av Waybiller-programvaren til norsk.</p>
          <p> Mer å komme!</p>
        </>
    ),
  },
  {
    image: (
        <img src={cars} alt="cars"/>
    ),
    subtitle: 'Det innledende arbeidet er godt i gang, norskalternativene vil snart være klare.',
    date: '10.09.2020',
    text: (
        <>
          <p>Det første arbeidet er godt i gang, og plattformen og destinasjonssidene gjør seg klar for den norske markedsintroduksjonen. Vi samler også en liste over potensielle kandidater og pilotkunder i målgruppen</p>
          <p>
            Vil du prøve waybiller? <a href="mailto: katarina@uppercase.no">Kontakt oss her</a>
          </p>
        </>
    ),
  },
  {
    subtitle: 'Norwegian er tilgjengelig + potensiell første pilotkunde nådd!',
    date: '20.10.2020',
    text: (
        <p>
          Norsk er tilgjengelig. Vi lanserte endelig tjenesten med norskalternativ, og bare mindre språkjusteringer er igjen på selve plattformen for å optimalisere den for norsk språk. Dessuten oppnås en potensiell første pilotkunde og dialogen er lovende.
        </p>
    ),
  },
];


function Progress({english}: Props) {
  const content = english ? englishContent : norwegianContent;

  return (
      <div className="Progress">
        <div className="Inner-wrapper">
          <h4>{english ? 'Progress' : 'Framgang'}</h4>
          {content.map((item, key) => (
              <div className="Content-block" key={key}>
                {item.image && item.image}
                <p><b>{item.subtitle}</b></p>
                <small>{item.date}</small>
                {item.text}
              </div>
          ))}
        </div>
      </div>
  );
}

export default Progress;
